<template>
  <div class="orders">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="stat-widget-five">
              <div class="stat-icon dib flat-color-1">
                <i class="s7 s7-server"></i>
              </div>
              <div class="stat-content">
                <div class="text-left dib">
                  <div class="stat-text">
                    <span v-if="!settings" class="count">0</span>
                    <span v-else class="count">{{settings.total_courses}}</span>
                  </div>
                  <div class="stat-heading"><router-link to="/courses">Cursos</router-link>  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
          <div class="card">
              <div class="card-body">
                  <div class="stat-widget-five">
                      <div class="stat-icon dib flat-color-2">
                          <i class="s7 s7-study"></i>
                      </div>
                      <div class="stat-content">
                          <div class="text-left dib">
                              <div class="stat-text">
                                <span v-if="!settings" class="count">0</span>
                                <span v-else class="count">{{settings.promedio}}</span></div>
                              <div class="stat-heading">
                                <!--<router-link to="/notes">Promedio</router-link> -->
                                Promedio
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col">
          <div class="card">
              <div class="card-body">
                  <div class="stat-widget-five">
                      <div class="stat-icon dib flat-color-3">
                          <i class="s7 s7-info"></i>
                      </div>
                      <div class="stat-content">
                          <div class="text-left dib">
                              <div class="stat-text">
                                <span v-if="!settings" class="count">0</span>
                                <span v-else class="count">{{settings.total_sanciones}}</span></div>
                              <div class="stat-heading">
                                <router-link to="/sanciones">Sanciones</router-link> </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col">
          <div class="card">
              <div class="card-body">
                  <div class="stat-widget-five">
                      <div class="stat-icon dib flat-color-4">
                          <i class="s7 s7-pin"></i>
                      </div>
                      <div class="stat-content">
                          <div class="text-left dib">
                              <div class="stat-text"> %
                                <span v-if="!settings" class="count">0</span>
                                <span v-else class="count">{{settings.porcentaje_asistencia}}</span>
                              </div>
                              <div class="stat-heading">
                                <router-link to="/assistance">Asistencia</router-link> </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <!-- /Widgets -->
    <!--  Traffic  -->
    <div class="orders">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h4 class="box-title">Horario sesiones virtuales </h4>
            </div>
            <div class="card-body--">
              <div class="d-none d-sm-block table-stats order-table ov-h" style="overflow-x: auto;">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th v-for="(item, key) in horario_obj.horas"
                      :key="key" style="text-align: center;">
                        {{item.hora_nombre}}
                        <br>
                        <span class="badge bg-flat-color-2">
                        {{item.hora_inicio}} - {{item.hora_fin}}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(dia, k) in horario_obj.dias" :key="k">
                      <td><span class="badge bg-flat-color-2">{{dia.dia_nombre}}</span></td>
                      <td v-for="(hora, index) in horario_obj.horas" :key="index">
                        <p v-for="(detalle, i) in show_detalle(hora.hora_id, dia.dia_id)" :key="i">
                          <span style="font-size: 13px;">
                            {{detalle.course_name}} {{detalle.section_name}} -
                           {{detalle.teacher_name}}</span>
                           <br>
                          <a style="margin: 3px;"
                          v-if="detalle.link_zoom" class="badge badge-success"
                          target="_blank" :href="detalle.link_zoom"
                          title="Click para ir a sessión en vivo por zoom">
                          Ir a zoom
                          </a>
                          <a
                          v-if="detalle.link_meet" class="badge badge-primary"
                          target="_blank" :href="detalle.link_meet"
                          title="Click para ir a sessión en vivo por meet">
                          Ir a meet
                          </a>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> <!-- /.table-stats -->
              <div class="d-block d-sm-none table-stats order-table ov-h"
              v-if="horario_obj.detalle">
                <span
                 v-for="(day, index) in group_by_dia"
                 is="tr" :key="index">
                  <table class="table"
                  style="border: 1px solid gray;"
                  v-for="(entry, i) in day.sort((a, b) => a.orden_hora -b.orden_hora )"
                  :key="i" >
                    <thead>
                      <th>
                        <span class="badge bg-flat-color-1" style="font-size: 12px;">
                        {{entry.dia_nombre}} </span>
                      </th>
                      <th>
                        <span class="badge bg-flat-color-1" style="font-size: 12px;">
                          {{entry.hora_fin}} - {{entry.hora_inicio}}
                        </span>
                      </th>
                    </thead>
                    <tbody >
                      <tr >
                        <th style="width: 10%"
                        colspan="2" class="text-center">
                        <span >
                        {{entry.course_name}} - {{entry.section_name}}</span>
                      </th>
                      </tr>
                      <tr >
                        <td style="width: 10%; color: #868e96;"
                        colspan="2" class="text-center ">{{entry.teacher_name}}</td>
                      </tr>
                      <tr >
                        <td v-if="entry.link_zoom"
                          title="Click para ir sesión por zoom"
                          class="text-center">
                            <a target="_blank" :href="entry.link_zoom"
                            class="right badge badge-success"
                          title="Ir a sessión en vivo por zoom"> Ir a zoom</a>
                        </td>
                        <td v-if="!entry.link_zoom">
                        </td>
                        <td v-if="entry.link_meet"
                         class="text-center">
                          <a target="_blank" :href="entry.link_meet"
                          class="badge badge-primary"
                          title="Ir a sessión en vivo por meet">Ir a meet</a>
                        </td>
                        <td v-if="!entry.link_meet">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
              </div> <!-- /.table-stats -->
            </div>
          </div> <!-- /.card -->
        </div>  <!-- /.col-lg-8 -->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="box-title">Cursos asignados </h4>
            <courses> </courses>
          </div>
        </div>
      </div><!-- /# column -->
    </div>
    <!--  /Traffic -->
    <div class="clearfix"></div>
    <!-- Orders -->
    <div class="orders">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h4 class="box-title">Actividades </h4>
            </div>
            <div class="card-body--">
              <div class="table-stats order-table ov-h">
                <table class="table ">
                       <thead>
                    <tr>
                      <th class="serial">#</th>
                      <th >Curso</th>
                      <th>Nombre actividad</th>
                      <th class="serial">Fecha entrega </th>
                      <th class="serial" title="El valor de la actividad">Ponderación</th>
                      <th title="Nota obtenida">Mi nota</th>
                      <th class="serial">Entregada?</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(item, index) in tasks" :key="index">
                      <td class="serial">{{index + 1}}</td>
                      <td>{{item.task_obj.course_name}}</td>
                      <td><span >{{item.task_obj.name}}</span> </td>
                      <td class="serial"><span >{{item.task_obj.date_activity|to_date}}</span> </td>
                      <td class="serial"><span >{{item.task_obj.note}}</span></td>
                      <td>
                        <span v-if="item.note">{{item.note}}</span>
                        <span v-else>0</span>
                      </td>
                      <td class="serial">
                        <span v-if="item.done" class="badge badge-complete">
                          {{item.done|status_to_text}}</span>
                        <span v-else class="badge badge-pending">
                          {{item.done|status_to_text}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> <!-- /.table-stats -->
            </div>
          </div> <!-- /.card -->
        </div>  <!-- /.col-lg-8 -->
        </div>
    </div>
    <!-- /.orders -->
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';

export default {
  created() {
    if (!window.localStorage.getItem('_token')) {
      this.$store.commit('SET_LAYOUT', 'login');
    } else {
      this.$store.commit('SET_LAYOUT', 'default');
      this.get_settings();
    }
  },
  mounted() {
    this.list_task();
    this.get_horario();
  },
  data() {
    return {
      tasks: [],
      url: 'tasks/',
      label_course: [],
      values: [],
      horario_obj: [],
    };
  },
  name: 'Home',
  components: {
    courses: () => import('../views/Courses.vue'),
  },
  methods: {
    show_detalle(horaId, diaId) {
      let result = {};
      result = this.horario_obj.detalle
        .filter((detalle) => detalle.hora_id === horaId && detalle.dia_id === diaId);

      if (result.length > 0) {
        return result;
      }

      return undefined;
    },
    handlerError(error) {
      if (!error.response) {
        console.log(error, 'Erroir');
        this.$swal.fire('Error!', error, 'error');
        return false;
      }
      if (typeof (error.response.data) === 'object') {
        const result = error.response.data;
        Object.entries(result).forEach(
          ([key, value]) => this.$swal.fire('Error!', `${key} : ${value}`, 'error'),
        );
        return false;
      }
      this.$swal.fire('Error!', error.response.data, 'error');
      return false;
    },
    get_settings() {
      const payload = {};
      const self = this;
      self.sendRequest(payload, 'settings/').then((data) => {
        const settings = data.data;
        self.$store.dispatch('set_data_settgins', { settings });
        delete settings.asistencia_course;
        delete settings.top_list_courses;
        delete settings.logo_url;
        delete settings.stamp_url;
        self.save_storage('settings', settings);
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            this.Logout();
          }
        }
        this.handlerError(error);
      });
    },
    Logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      }).catch((error) => {
        console.error(error);
        this.$swal.fire('Error!', 'No fue posible finalizar la sesión.', 'error');
      });
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    to_course_array(settings) {
      this.label_course = Array.from(settings.top_list_courses, (x) => x.course_name);
      return [];
    },
    to_data_array(settings) {
      this.values = Array.from(settings.top_list_courses, (x) => x.note);
      return [];
    },
    list_task() {
      const payload = {
        limit: 10,
      };
      const self = this;
      this.sendRequest(payload, this.url).then((data) => {
        self.tasks = data.data;
      }).catch((error) => {
        this.handlerError(error);
        console.log(error.response.data);
      });
      return null;
    },
    get_horario() {
      const payload = {
        for_student: 10,
      };
      const self = this;
      this.sendRequest(payload, 'detalle_horarios_student/').then((data) => {
        self.horario_obj = data.data;
      }).catch((error) => {
        console.log(error);
        this.handlerError(error);
      });
      return null;
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url, { params: payload });
      return result;
    },
  },
  filters: {
    status_to_text(value) {
      if (value) return 'Sí';
      return 'No';
    },
    to_date(value) {
      if (!value) return '-----';
      return moment(value, 'YYYY-MM-DD', 'es').format('ll');
    },
  },
  computed: {
    settings() {
      return this.$store.state.settings_obj;
    },
    /* eslint no-param-reassign: "error" */
    group_by_dia() {
      const group = this.horario_obj.detalle.reduce((r, a) => {
        r[a.orden_dia] = [...r[a.orden_dia] || [], a];
        return r;
      }, {});
      return group;
    },
  },
};
</script>
<style lang="css" scoped>
    #flotLine5  {
          height: 105px;
    }

    #flotBarChart {
        height: 150px;
    }
    #cellPaiChart{
        height: 160px;
    }
    .on-over {
      cursor: pointer;
    }
</style>
